import React, { useRef, useEffect, FunctionComponent } from 'react'
import styled from 'styled-components'
import { isDescendant } from '../util/isDescendant'
import theme from './styling/theme'
import arrow from '../images/nav-arrow.svg'

let xPos = 0
let yPos = 0
let mouseXPos = 0
let mouseYPos = 0

interface CursorProps {
  id: string
}
const Cursor: FunctionComponent<CursorProps> = ({ id }) => {
  const cursorFollow = useRef()
  const followSpeed = 5
  const animate = () => {
    const distX = mouseXPos - xPos
    const distY = mouseYPos - yPos

    if (distX > -0.1 && distX < 0.1) {
    } else {
      xPos += distX / followSpeed
      if (
        cursorFollow.current !== null &&
        cursorFollow.current !== 'undefined'
      ) {
        cursorFollow.current.style.left = `${xPos}px`
      }
    }

    if (distY > -0.1 && distY < 0.1) {
    } else {
      yPos += distY / followSpeed
      if (cursorFollow.current !== null) {
        cursorFollow.current.style.top = `${yPos}px`
      }
    }
  }

  // ========================= ON MOUNT
  useEffect(() => {
    // Start animation loop (1s/ xframe: frames per sec)
    if (!window.innerWidth < 768) {
      window.setInterval(() => animate(), 1000 / 60)
    }
  }, [])

  // ============================ ON DISMOUNT
  useEffect(() => {
    return () => {
      clearInterval()
    }
  }, [])

  // ================================ ON MOUSE MOVE
  const onMouseMove = event => {
    const {
      clientX: x,
      clientY: y,
      target: { tagName, className },
    } = event
    document.body.style.cursor = 'default'
    mouseXPos = x - theme.cursorFollowSize / 2
    mouseYPos = y - theme.cursorFollowSize / 2
    if (cursorFollow.current !== null) {
      cursorFollow.current.style.width = `${theme.cursorFollowSize}px`
      cursorFollow.current.style.height = `${theme.cursorFollowSize}px`
    }
    // Hovering Carousel section
    if (typeof window !== 'undefined') {
      if (window.innerWidth > 1200) {
        if (isDescendant(id, event.target, true)) {
          // cursorFollowGrow(cursorFollow.current, theme.cursorFollowSize)
          cursorFollow.current.style.opacity = 1
          document.body.style.cursor = 'grab'
        }
        // Hovering A tags
        if (
          isDescendant('A', event.target) ||
          tagName === 'A' ||
          tagName === 'circle' ||
          tagName === 'svg' ||
          className === 'swiper-scrollbar-drag' ||
          className === 'swiper-button-next'
        ) {
          // cursorFollowGrow(cursorFollow.current, theme.cursorFollowSize)
          cursorFollow.current.style.opacity = 0
        }
      }
    }
  }
  // ================================ ON MOUSE OUT
  const onMouseOut = () => {
    cursorFollow.current.style.opacity = 0
    document.body.style.cursor = 'default'
  }
  // ================================ ON MOUNT
  useEffect(() => {
    const carouselDrag = document.getElementById(id)

    carouselDrag.addEventListener('mousemove', onMouseMove)
    carouselDrag.addEventListener('mouseout', onMouseOut)

    return () => {
      carouselDrag.removeEventListener('mousemove', onMouseMove)
    }
  }, [])

  return (
    <>
      <CursorFollow ref={cursorFollow} />
    </>
  )
}

const CursorFollow = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  pointer-events: none;
  border-radius: 50%;
  border: 1px solid ${theme.text.main};
  z-index: 20;
  background: transparent;
  transition: transform 0.1s ease, opacity 0.5s ease;
  transition-delay: 0s;
  opacity: 0;
  &::before {
    content: url(${arrow});
    width: 15px;
    height: 15px;
    display: block;
    transform: rotate(-90deg) scale(1.1);
    position: relative;
    left: 33px;
    top: 25%;
  }
  &::after {
    content: url(${arrow});
    width: 15px;
    height: 15px;
    display: block;
    transform: rotate(90deg) scale(1.1);
    position: relative;
    left: -10px;
    top: -3px;
  }
`

export default Cursor
