import React, {
  useLayoutEffect,
  useEffect,
  useState,
  FunctionComponent,
} from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import InlineLink from './shared/InlineLink'
import img1 from '../images/homepage/icons-with-text/10Billion.svg'
import arr from '../images/arrCarousel.svg'
import arrHovered from '../images/arrCarouselHovered.svg'
import theme from './styling/theme'
import Cursor from './Cursor'
import SectionHeading from './shared/SectionHeading'
import VerticalPadding from './styling/VerticalPadding'
import SwiperCore, { Navigation, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

// install Swiper components
SwiperCore.use([Navigation, Scrollbar])

const carouselData = [
  {
    img: img1,
    companyName: 'Hilton Food Group',
    content:
      '"I had a limited understanding of RDA tax relief before talking to them and I had received a few emails, but I was unsure that it applied to our business.  We initially began to work with RDA and understand them, and we have been pleased with the process and the outcome, achieving great results each time."',
    url: '/hilton-food',
    category: 'Global Food Supplier',
    turnover: 'Annual turnover: £2.77bn',
  },
  {
    img: img1,
    companyName: 'Weston Homes PLC',
    content:
      '"Following some initial conversations with RDA. We looked into the feasibility of submitting a claim on a no win no fee basis. The RDA team took the time to understand our business and met various key people within each division of our companies to establish what information was needed."',
    url: '/weston-homes',
    category: 'Construction Company',
    turnover: 'Annual turnover: £300m',
  },
  {
    img: img1,
    companyName: 'PPS Commercials',
    content:
      '"We hadn’t made any R&D tax credit claims before, and it was our accountant who recommended we speak to RDA. I spoke with the team at RDA and they made us feel totally at ease. As we went through the process it was extremely seamless, all the hard work was taken care of by RDA."',
    url: '/pps-commercials',
    category: 'Body Builders, Automotive specialists',
    turnover: 'Annual turnover: £16m',
  },
  {
    img: img1,
    companyName: 'MLM Group',
    content:
      '“Following some initial conversations with Kevin from RDA and Jim Rudd, an Infrastructure Director from MLM, we looked into the feasibility of submitting a claim…”',
    url: '/mlm-group',
    category: 'Engineering, environmental & building control consultancy',
    turnover: 'Annual turnover: £24m (2019)',
  },

  {
    img: img1,
    companyName: 'Caps Cases',
    content:
      '“Before talking to RDA, we had previously completed our own claims, and whilst we were aware of the scheme, it was viewed as something that wouldn’t be a valuable use of our limited resources…”',
    url: '/caps-cases',
    category: 'Packing Design & Manufacture',
    turnover: 'Annual turnover: n/a',
  },

  {
    img: img1,
    companyName: 'Beaumont Seymour',
    content:
      '“We have been working with Kevin Auchoybur and his team successfully for the last 12 months with many of our clients. The professionalism, knowledge and care applied in relation to all of the R&D Tax Credit claims we have submitted to date is quite simply second to none.',
    url: '/beaumont-seymour',
    category: 'Accountancy',
    turnover: 'Annual turnover: n/a – third-party client support',
  },
  {
    img: img1,
    companyName: 'Modus Engineering Services',
    content:
      '"From the outset, Lee, Jim and the team at RDA have been most professional and helpful. Applying expert knowledge, ultimately resulting in a successful claim."',
    url: '/modus-engineering-services',
    category: 'Engineering',
    turnover: 'Annual turnover: n/a',
  },
  {
    img: img1,
    companyName: 'Skyline Green Interiors',
    content:
      '“We hadn’t made any R&D claims before, and it was our accountant who recommended we speak to RDA. I spoke with Gary Waskett and he made us feel totally at ease – he’s a very personable guy who guided us through things.',
    url: '/skyline-green-interiors',
    category: 'Engineering, environmental & building control consultancy',
    turnover: 'Annual turnover: n/a – third-party client support',
  },
  {
    img: img1,
    companyName: 'Hut Architecture',
    content:
      '“I had a limited understanding of R&D tax relief before engaging RDA. I had received a few emails but I wasn’t sure that it applied to our business.',
    url: '/hut-architecture',
    category: '',
    turnover: 'Annual turnover: n/a',
  },
  {
    img: img1,
    companyName: 'Rise Scaffold',
    content:
      '“Before dealing with RDA, my understanding of the R&D scheme was very limited; we had thought it was for medical industries. But having been through the process with them, we are now finding that we are looking at more innovative solutions to projects as we are aware that there could be financial support for applying new methods and designs. ',
    url: '/rise-scaffold',
    category: '',
    turnover: 'Annual turnover: n/a',
  },
  {
    img: img1,
    companyName: 'ECCC',
    content:
      '“I have had the pleasure of knowing Kevin at RDA for many years now and I continuously hear of the success stories of claims the firm have been securing for their clients. ',
    url: '/eccc',
    category: '',
    turnover: 'Annual turnover: n/a',
  },
  {
    img: img1,
    companyName: 'Tom Gamble Sponsorship',
    content:
      '“I have known Kevin at RDA for over 10 years now. Only in the last 18 months have we managed to align the stars and make a sponsorship opportunity happen.',
    url: '/tom-gamble-sponsorship',
    category: '',
    turnover: 'Annual turnover: n/a',
  },
  {
    img: img1,
    companyName: 'Wesley James Ltd',
    content:
      'I was recommended to use RDA by a professional colleague and they have provided an exceptional level of service and knowledge. Compared to other competitors they are by far the easiest for my clients to work with and I would 100% recommend them to anyone looking for assistance on an R&D claim.',
    url: '/wesley-james-ltd',
    category: 'Accountant',
    turnover: 'Annual turnover: n/a',
  },
]
const heading = 'How we’ve helped our clients'

interface CarouselDragProps {
  id: string
}
const CarouselDrag: FunctionComponent<CarouselDragProps> = ({ id }) => {
  const [slides, setSlides] = useState(3)
  const [padding, setPadding] = useState(20)
  const [charLimit, setCharLimit] = React.useState(140)

  const formatText = (text: string) => {
    if (text.length < charLimit) {
      return text
    }
    return text.substr(0, charLimit) + '...'
  }
  useLayoutEffect(() => {
    const hideArrows = () => {
      if (typeof window !== 'undefined') {
        const nextBtn = document.querySelector('.swiper-button-next')
        const prevBtn = document.querySelector('.swiper-button-prev')
        if (window.innerWidth >= 1200 && carouselData.length <= 3) {
          nextBtn.style.display = 'none'
          prevBtn.style.display = 'none'
        } else if (window.innerWidth < 1200 && carouselData.length > 3) {
          nextBtn.style.display = 'inline-block'
          prevBtn.style.display = 'inline-block'
        }
      }
    }

    function updateSize() {
      if (typeof window !== 'undefined') {
        if (window.innerWidth <= 767) {
          setSlides(window.innerWidth / (320 + padding * 3))
        } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
          setSlides(window.innerWidth / (360 + padding * 2.6))
        } else if (window.innerWidth >= 1024 && window.innerWidth < 1200) {
          setSlides(window.innerWidth / (360 + padding * 2.5))
        } else {
          setSlides(3.05)
        }
      }
    }
    window.addEventListener('resize', updateSize)
    // window.addEventListener('resize', hideArrows)
    updateSize()
    // hideArrows()
    return () => {
      window.removeEventListener('resize', updateSize)
      // window.removeEventListener('resize', hideArrows)
    }
  }, [])

  return (
    <VerticalPadding>
      <Container>
        <Cursor id={id} />
        <HeadingContainer>
          <p className="section-tag">CUSTOMER CASE STUDIES</p>
          <SectionHeading center>{heading}</SectionHeading>
        </HeadingContainer>
        <SwiperContainer>
          <Swiper
            className="swiper-container"
            slidesPerView={slides}
            navigation
            scrollbar={{
              hide: false,
            }}
          >
            <SwipperWrapper id={id} className="swiper-wrapper">
              {carouselData.map((item, i) => {
                return (
                  <SwiperSlide key={i} className="carousel-drag-slide">
                    <ImageContainer>
                      {/* <Image src={item.img} /> */}
                      <CompanyName>{item.companyName}</CompanyName>
                    </ImageContainer>
                    {item.content && (
                      <Content className="p-large">
                        {formatText(item.content)}
                      </Content>
                    )}
                    {item.url && (
                      <InlineLink
                        to={
                          (process.env.BASE === '/' ? '' : process.env.BASE) +
                          item.url
                        }
                        text={'Read more'}
                        marginbottom="33px"
                        icon
                      />
                    )}
                    <Line />
                    {item.category && <Category>{item.category}</Category>}
                    {item.turnover && <Turnover>{item.turnover}</Turnover>}
                  </SwiperSlide>
                )
              })}
            </SwipperWrapper>
          </Swiper>
        </SwiperContainer>
      </Container>
      <InlineLinkWrapper noMarginTop={carouselData.length <= 3}>
        <InlineLink
          text="View all case studies"
          icon
          to={
            (process.env.BASE === '/' ? '' : process.env.BASE) + '/case-studies'
          }
        />
      </InlineLinkWrapper>
    </VerticalPadding>
  )
}

export default CarouselDrag

const Container = styled.section`
  padding: 25px 20px 50px;
  overflow: hidden;
  @media (min-width: 768px) {
    padding: 50px 20px;
  }
  @media (min-width: 1024px) {
    padding: 50px 3%;
  }
  @media (min-width: 1199px) {
    padding: 50px 0;
  }
  .swiper-container {
    overflow: visible;
    transition: transform 0.8s ease;
    width: 100%;
    margin-right: auto;
    transition: transform 0.4s ease;
    @media (min-width: 1024px) {
      width: 100%;
    }
  }
  .carousel-drag-slide {
    padding: 35px 37px 37px;
    background: white;
    width: 320px !important;
    min-width: 320px !important;
    height: auto;
    transition: transform 1s ease;
    margin-right: 20px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);

    @media (min-width: 768px) {
      padding: 55px 40px 48px;
      width: 360px !important;
      min-width: 360px !important;
    }
  }

  .swiper-scrollbar {
    position: absolute;
    height: 1px;
    width: calc(100vw - 140px);
    background: ${theme.grey.scroll};
    left: 100px;
    bottom: -40px;
    @media screen and (min-width: 1024px) {
      width: calc(100vw - 6% - 100px);
    }
    @media screen and (min-width: 1200px) {
      width: 92%;
    }
  }
  .swiper-scrollbar-drag {
    height: 3px;
    position: absolute;
    top: -1px;
    background: ${theme.brand.blue};
  }

  .swiper-button-next {
    margin-left: 18px;
    left: 15px;
  }

  .swiper-button-prev {
    transform: rotate(-180deg);
    left: 0;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    opacity: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: -50px;
    width: 4.9rem;
    height: 1rem;
    margin: 0;
  }
  .swiper-button-next {
    left: 50px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background: url(${arr});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 33px;
    height: 20px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background: url(${arrHovered});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 33px;
    height: 20px;
  }
  .swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
`

const HeadingContainer = styled.div`
  width: 100%;
  max-width: 918px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 45px;
  }
`

const SwiperContainer = styled.div`
  cursor: grab;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
`
const SwipperWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
`
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
`
const CompanyName = styled.h6`
  font-weight: 700;
  margin-left: 3px;
`

const Image = styled.img`
  height: 40px;
  width: 40px;
`

const Content = styled.p`
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-bottom: 58px;
  }
`
const Line = styled.div`
  background: ${theme.grey.keylineDark};
  margin: 0 0 30px;
  height: 1px;
`

const Category = styled.p`
  color: ${theme.brand.blue};
  font-weight: 700;
  margin-bottom: 2px;
`

const Turnover = styled.p`
  color: ${theme.brand.blue};
`
interface ILinkWrapper {
  noMarginTop?: boolean
}
const InlineLinkWrapper = styled.div<ILinkWrapper>`
  margin-top: 22px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  @media (min-width: 1024px) {
    margin-top: 47px;
  }
  @media (min-width: 1200px) {
    margin-top: ${props => (props.noMarginTop ? '10px' : '47px')};
  }
`
